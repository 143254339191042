@import './colors';
@import './spacing';
@import './typography';
@import './dimensions';

.container {
  border-bottom: 1px solid $border-gray;
  display: flex;
  scroll-margin-top: $top-bar-height;
}

.container:last-of-type {
  border: 0;
}

.term {
  @extend %smallHeadline;
  border-right: 1px solid $border-gray;
  padding: $spacing-3 $spacing-2;
  width: 30%;
}

.description {
  @extend %bodyLarge;
  padding: $spacing-3 $spacing-2;
  width: 70%;
}
