@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

$extra-bottom-spacing: $gray-box-title-height + $spacing-3;

.container {
  margin: $spacing-3 auto 0;
}

.extraBottomSpacing {
  margin-bottom: $extra-bottom-spacing;
}

.narrowWidth {
  width: 520px;
}

.title {
  @extend %smallHeadline;
  color: $white;
  padding: $spacing-1 0;
  text-align: center;
}

.containerTeal {
  .title {
    background-color: $vroom-dark-teal;
  }

  .blockContainer {
    background-color: rgba(0, 135, 140, 0.05);
  }
}

.containerGreen {
  .title {
    background-color: $secondary-green;
  }

  .blockContainer {
    background-color: rgba(33, 150, 83, 0.1);
  }
}

.containerRed {
  .title {
    background-color: $secondary-red;
  }

  .blockContainer {
    background-color: rgba(235, 87, 87, 0.06);
  }
}

.titleTeal {
  .title {
    background-color: $vroom-dark-teal;
  }
}

.blockContainer {
  position: relative;
  text-align: center;

  p {
    margin: 0;
    padding: $spacing-3 0;
  }

  ul {
    padding-top: $spacing-2;
    text-align: left;

    li {
      padding-bottom: $spacing-1;
    }
  }
}

.hintText {
  color: $secondary-red;
  font-size: 15px;
  font-weight: 500;
  left: -85px;
  position: absolute;
  top: 55px;
  transform: translate(-50%, -50%);
  width: 120px;
}

.coloredLine {
  border: 1px solid $secondary-red;
  left: 160px;
  position: absolute;
  top: 60px;
  transform: translate(-50%, -50%);
  width: 66px;
}
