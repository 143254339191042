@import './colors';
@import './spacing';
@import './typography';

.container {
  padding: $spacing-4;
}

.title {
  @extend %headline;
  color: $vroom-link-teal;
}

.list {
  border: 1px solid $border-gray;
}

.headerRow {
  display: none;
}
