@import './colors';
@import './dimensions';
@import './spacing';

$content-border-width: 6px;

.main {
  background-color: $background-gray;
  min-height: 100vh;
  overflow-x: hidden;
}

.loadingOverlay {
  background-color: $white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.contentContainer {
  margin-left: $left-navigation-width;
  margin-top: $top-bar-height;
  max-width: $max-width-content;
  min-height: calc(100vh - #{$top-bar-height} - #{$footer-height});
  padding: $spacing-4 $spacing-3;
}

.content {
  background: $white;
  border-radius: $content-border-radius;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.05);
}

.contentBlue {
  border-top: $content-border-width solid $secondary-blue;
}

.contentOrange {
  border-top: $content-border-width solid $vroom-dark-orange;
}

.contentPurple {
  border-top: $content-border-width solid $secondary-dark-purple;
}

.contentTeal {
  border-top: $content-border-width solid $vroom-link-teal;
}

.contentDarkTeal {
  border-top: $content-border-width solid $vroom-dark-teal;
}

.contentQuiz {
  background-color: $vroom-dark-teal;
  position: relative;
}

.innerContent {
  padding: $spacing-4;
}
