@import './colors';
@import './dimensions';
@import './spacing';
@import './zIndex';

.container {
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  height: 100vh;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: $left-navigation-width;
  z-index: $left-navigation-z-index;
}

.header {
  align-items: center;
  display: flex;
  height: $top-bar-height;
  justify-content: space-between;
  padding: 0 $spacing-3;
}

.logo {
  width: 67px;
}

.nonTrainingPageListContainer {
  margin-bottom: $spacing-5;
  margin-top: $spacing-3;
}

.nonTrainingPageList {
  border-top: 1px solid $border-gray;
  margin: 0 $spacing-3;
  padding: $spacing-3 0;
}

.pageTitle {
  display: flex;

  & + & {
    margin-top: $spacing-3;
  }
}

.pageIcon {
  color: $link-gray;
  margin-top: 3px;
  width: 16px;
}

.pageLink {
  color: $link-gray;
  font-size: 14px;
  padding-left: $spacing-2;
  text-decoration: none;
}

.currentPage {
  .pageIcon,
  .pageLink {
    color: $vroom-link-teal;
  }

  .pageLink {
    font-weight: 600;
  }
}
