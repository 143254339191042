@import './colors';
@import './spacing';
@import './typography';

$button-height: 48px;

.container {
  align-items: center;
  background-color: $vroom-link-teal;
  border: 0;
  border-radius: $button-height;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $omnes-font-family;
  font-size: 15px;
  font-weight: 600;
  height: $button-height;
  justify-content: center;
  margin-bottom: $spacing-2;
  margin-top: $spacing-2;
  padding-left: $spacing-3;
  padding-right: $spacing-3;
  text-decoration: none;

  &:hover {
    background-color: $vroom-dark-teal;
  }
}

.secondary {
  background-color: $white;
  border: 1.5px solid $border-gray;
  color: $dark-gray;

  &:hover {
    background-color: $light-gray;
  }
}
