@import './dimensions';
@import './spacing';

.center {
  text-align: center;
}

.footer {
  .image {
    border-bottom-left-radius: $content-border-radius;
    border-bottom-right-radius: $content-border-radius;
    display: block;
  }
}

.topSpacing {
  padding-top: $spacing-4;
}

.halfTopSpacing {
  padding-top: $spacing-3;
}

.noSpacing {
  padding-top: 0;
}

.image {
  max-width: 100%;
}
