@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

.container {
  background-position: top right;
  background-repeat: no-repeat;
}

.containerSectionIndex {
  .content {
    border-bottom: 1px solid $border-gray;
    padding-bottom: $spacing-4;
  }

  .title {
    margin: 0 0 $spacing-4;
    max-width: 425px;
  }

  .titleBlue {
    color: $secondary-blue;
  }

  .titleOrange {
    color: $vroom-dark-orange;
  }

  .titlePurple {
    color: $secondary-dark-purple;
  }

  .titleTeal {
    color: $vroom-link-teal;
  }

  .subtitle,
  .description {
    max-width: 500px;
  }
}

.containerQuiz {
  .content {
    padding-bottom: $spacing-4;
  }

  .title,
  .subtitle,
  .description {
    color: $white;
  }
}

.containerHalfBottomSpacing {
  .content {
    padding-bottom: $spacing-4;
  }
}

.content {
  margin: 0 $spacing-4;
  max-width: $max-width;
  padding: $spacing-4 0 $spacing-5;
}

.sectionTitle {
  color: $gray;
  font-size: 15px;
  font-weight: 500;
  line-height: 168.66%;
  margin-bottom: $spacing-1;
  text-transform: uppercase;
}

.title {
  @extend %headline;
  color: $vroom-link-teal;
  margin: 0;
  max-width: $max-width;
}

.title + .subtitle {
  margin-top: $spacing-2;
}

.subtitle {
  @extend %secondaryHeadline;
  max-width: 525px;
}

.description {
  @extend %bodyLarge;
  margin-top: $spacing-2;
  max-width: $max-width;
}

.subtitle,
.description {
  p {
    margin: 0;
  }
}
