@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

$extra-bottom-spacing: $gray-box-title-height + $spacing-3;

.container {
  margin: $spacing-3 auto 0;
  width: 520px;

  .title {
    background-color: $vroom-dark-teal;
  }
}

.title {
  @extend %smallHeadline;
  color: $white;
  padding: $spacing-1 0;
  text-align: center;
}

.contentContainer {
  display: flex;

  ul {
    flex-basis: 50%;
    margin-top: 0;
    padding: $spacing-2;
  }
}

.proList {
  li {
    background: url('/icons/proIcon.svg') no-repeat left top 3px;
    list-style: none;
    margin: 0;
    padding: 0 $spacing-2 $spacing-2 25px;
  }
}

.conList {
  li {
    background: url('/icons/conIcon.svg') no-repeat left top 3px;
    list-style: none;
    margin: 0;
    padding: 0 $spacing-2 $spacing-2 25px;
  }
}
