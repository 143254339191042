@import './colors';
@import './spacing';

$title-width: 216px;
$icon-width: 16px;

.section + .section {
  padding-top: $spacing-3;
}

.sectionTitle {
  box-sizing: content-box;
  display: flex;
  padding: $spacing-1 $spacing-3;

  svg {
    margin-top: 3px;
    width: $icon-width;
  }
}

.currentSectionTitle {
  background: $background-light-blue;
  color: $vroom-link-teal;

  .sectionLink {
    color: $vroom-link-teal;
    font-weight: 600;
  }
}

.sectionLink {
  flex-grow: 1;
  max-width: $title-width - $icon-width;
  padding-left: $spacing-2;
}

.sectionLink,
.pageLink {
  color: $black;
  font-size: 14px;
  font-weight: 500;
  line-height: 168.66%;
  text-decoration: none;
}

.sectionLink {
  color: $black;
}

.pageLink {
  color: $link-gray;
}

.pageList {
  list-style-type: none;
  margin: 0;
  padding-left: $icon-width + $spacing-2 + $spacing-3;
  padding-top: $spacing-2;
}

.page + .page {
  padding-top: $spacing-1;
}

.currentPageLink {
  color: $vroom-link-teal;
  font-weight: 600;
}

.completedPageLink {
  color: $black;
  font-weight: 600;
}
