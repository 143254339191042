@import './colors';
@import './dimensions';
@import './shadow';
@import './spacing';
@import './zIndex';

.container {
  @extend %shadow;
  align-items: center;

  background-color: $white;
  display: flex;
  height: $top-bar-height;
  left: $left-navigation-width;
  padding: 0 $spacing-3;
  position: fixed;
  top: 0;
  width: calc(100% - #{$left-navigation-width});
  z-index: $top-bar-z-index;

  p + p,
  button + a,
  button + button {
    margin-left: $spacing-3;
  }
}

.header {
  font-weight: 600;
}

.rightSide {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.nextArrow {
  height: 16px;
  margin-left: $spacing-1;
}
