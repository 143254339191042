@import './spacing';

.container {
  display: flex;
  justify-content: center;
}

.spacingTopAndBottom {
  padding: $spacing-3;
}

.spacingNone {
  // remove the margin that is automatically applied by Embedly
  margin: -5px 0;
  padding: 0;
}

.large {
  width: 100%;
}

.small {
  width: 40%;
}
