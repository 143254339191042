@import './colors';
@import './spacing';
@import './typography';

$circle-size: $spacing-5;
$arrow-height: 23px;

.container {
  text-align: center;
}

.title {
  @extend %smallHeadline;
  margin: 0;
}

.stepsContainer {
  display: flex;
  justify-content: center;
  padding-top: $spacing-3;
}

.step {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.step + .step {
  padding-left: $spacing-4;
  position: relative;

  &::before {
    content: url('/icons/right-arrow.svg');
    left: $spacing-2;
    position: absolute;
    top: $circle-size / 2 - $arrow-height / 2;
  }
}

.stepCircle {
  align-items: center;
  border-radius: $circle-size / 2;
  display: flex;
  flex-direction: column;
  height: $circle-size;
  justify-content: center;
  padding: $spacing-2;
  width: $circle-size;
}

.stepCirclePurple {
  background: $secondary-dark-purple;
  color: $white;
}

.stepDescription {
  max-width: 210px;
  padding-top: $spacing-2;
}
