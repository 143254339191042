@import './spacing';

.container {
  display: flex;
  padding-bottom: $spacing-4;
}

.leftImage {
  flex-direction: row-reverse;
  padding-right: $spacing-4;

  .image {
    align-items: center;
    display: flex;
    padding-right: $spacing-4;
  }
}

.rightImage {
  padding-left: $spacing-4;

  .image {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: $spacing-4;
  }
}

.extraTopSpacing,
.extraTopAndBottomSpacing {
  padding-top: $spacing-5;
}

.extraBottomSpacing,
.extraTopAndBottomSpacing {
  padding-bottom: $spacing-5;
}

.text {
  flex: 1 1 auto;
  max-width: 330px;
}

.image {
  flex: 2 1 auto;

  img {
    max-width: 100%;
  }
}
