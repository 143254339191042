@import './spacing';
@import './typography';

$button-size: 20px;

.container {
  border-top: 1px $border-gray solid;
  padding: $spacing-3 0;
  position: relative;
}

.title {
  @extend %smallHeadline;
  max-width: 450px;
}

.content {
  max-width: 750px;
}

.button {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: $button-size;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: $spacing-3;
  width: $button-size;
}
