@import './spacing';
@import './colors';

.container {
  background-color: $vroom-dark-teal;
  padding: 0 $spacing-4 $spacing-5;
}

.tall {
  padding: 0 $spacing-4 $spacing-5;
}

.short {
  padding: 0 $spacing-4 $spacing-3;
}
