@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

$quote-bubble-width: 39px;

.content {
  max-width: $max-width-sm;

  h3 {
    @extend %smallHeadline;
    margin: 0 0 $spacing-2;
  }

  h3 + ul,
  h3 + ol,
  li ul {
    padding-top: $spacing-2;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  p + h3 {
    margin-top: $spacing-3;
  }

  p + p,
  p + ul,
  p + ol,
  ol + p {
    margin-top: $spacing-2;
  }

  li p + ul {
    margin-top: 0;
    padding-top: 0;
  }

  strong {
    font-weight: 600;
  }

  blockquote {
    background: url('/icons/quote-bubble.svg') no-repeat left top $spacing-3 - $spacing-1;
    font-style: italic;
    margin: 0;
    padding-left: $quote-bubble-width + $spacing-2;
    padding-top: $spacing-2;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul li,
  ol li ul li {
    background: url('/icons/checkmark.svg') no-repeat left top 4px;
    list-style: none;
    margin: 0;
    padding: 0 $spacing-2 $spacing-2 25px;
  }

  ol {
    margin: 0;
    padding-inline-start: 13px;

    li {
      margin: 0;
      padding: 0 $spacing-2 0 $spacing-1;
    }

    li + li {
      padding-top: $spacing-2;
    }

    li::before,
    li::marker {
      color: $vroom-link-teal;
      font-weight: 500;
    }

    strong:first-child {
      display: inline-block;
      padding-bottom: $spacing-1;
    }

    li ul:first-child {
      padding-top: $spacing-2;
    }
  }
}

.centered {
  margin: 0 auto;
  text-align: center;

  h3 {
    margin-bottom: $spacing-1;
  }

  h3 + p {
    margin-top: 0;
  }
}

.twoColumnList {
  max-width: none;

  ul {
    columns: 2;
  }
}

.threeColumnList {
  max-width: none;

  ul {
    columns: 3;
  }
}

.star {
  ul li,
  ol li ul li {
    background: url('/icons/star.svg') no-repeat left top 3px;
  }
}

.redX {
  ul li,
  ol li ul li {
    background: url('/icons/x.svg') no-repeat left top 2px;
  }
}

.narrowListSpacing {
  ul li,
  ol li ul li {
    padding: 0 $spacing-3 $spacing-1 25px;
  }
}
