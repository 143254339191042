@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

$image-spacing: 45px;

.container {
  margin: 0 auto;
  max-width: 425px;
  padding-bottom: $spacing-4;
}

.containerPurple {
  .contentContainer {
    background-color: $secondary-purple;
    color: $white;
  }
}

.containerRed {
  .contentContainer {
    background-color: $secondary-crimson;
    color: $white;
  }
}

.containerTeal {
  .contentContainer {
    background-color: $vroom-dark-teal;
    color: $white;
  }
}

.spacingTopAndNoBottom {
  padding-bottom: 0;
  padding-top: $spacing-4;
}

.spacingExtraBottom {
  padding-bottom: $spacing-5;
}

.contentContainer {
  border-radius: 30px;
  padding: $spacing-2 $image-spacing $image-spacing;
  position: relative;
  text-align: center;
}

.title {
  @extend %smallHeadline;
}

.content {
  padding-top: $spacing-1;

  p {
    margin: 0;
  }

  p + p {
    margin-top: $spacing-2;
  }

  p + ul {
    margin-top: $spacing-1;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  ul {
    padding-inline-start: $spacing-2;
  }
}

.contentLeftTextAlignment {
  text-align: left;
}

.topLeftImage {
  left: -32px;
  position: absolute;
  top: -20px;
}

.bottomRightImage {
  bottom: $spacing-2;
  position: absolute;
  right: $spacing-2;
}
