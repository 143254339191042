$max-width: 600px;
$max-width-sm: 400px;
$max-width-content: 1070px;

$content-border-radius: 10px;

$left-navigation-width: 300px;
$top-bar-height: 80px;
$footer-height: 100px;
$gray-box-title-height: 23px;
