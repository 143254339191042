@import './spacing';

.topLeftImage {
  left: $spacing-2;
  position: absolute;
  top: $spacing-2;
}

.topRightImage {
  position: absolute;
  right: $spacing-1;
  top: 48px;
}

.bottomLeftImage {
  bottom: $spacing-6;
  left: 0;
  position: absolute;
}

.bottomLeftImageLow {
  bottom: $spacing-2;
  left: 0;
  position: absolute;
}
