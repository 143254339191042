@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

.container {
  box-sizing: content-box;
  padding: 0 $spacing-4 $spacing-4;
}

.containerExtraTopSpacing,
.containerExtraTopAndBottomSpacing {
  padding-top: $spacing-5;
}

.containerExtraBottomSpacing,
.containerExtraTopAndBottomSpacing {
  padding-bottom: $spacing-5;
}

.containerNoBottomSpacing {
  padding-bottom: 0;
}

.content {
  background-color: $background-gray;
  border-radius: 30px;
  margin: 0 auto;
  padding: $spacing-3;
  width: $max-width-content - $spacing-4 * 2 - $spacing-3 * 5;
}

.title {
  @extend %smallHeadline;
  margin: 0;
  text-align: center;
}

.contentSideBySide {
  display: flex;
  justify-content: space-around;
  padding: 0 $spacing-4;
}

.contentText {
  div:first-child {
    margin: 0 auto;
  }

  p {
    text-align: center;
  }
}

.contentImage {
  margin-top: $spacing-3;
  text-align: center;
}

.contentPhotoCard + .contentPhotoCard {
  padding-left: $spacing-3;
}
