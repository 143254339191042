@import './colors';
@import './dimensions';
@import './spacing';
@import './typography';

$content-width: 550px;
$textarea-height: 150px;

.container {
  box-sizing: content-box;
  max-width: $content-width;
  padding: 0 $spacing-4 $spacing-4;
}

.title {
  @extend %smallHeadline;
  margin: 0;
}

.textContainer {
  height: $textarea-height;
  margin-top: $spacing-1;
  position: relative;
}

.textarea {
  background: $white;
  border: 1px solid $field-border-gray;
  border-radius: 6px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09);
  font-family: $omnes-font-family;
  font-size: 16px;
  font-weight: 500;
  height: $textarea-height;
  line-height: 18px;
  padding: $spacing-2;
  width: $content-width;

  &::placeholder {
    color: $placeholder-gray;
  }
}

.savingIndicator {
  bottom: $spacing-1;
  display: flex;
  left: $spacing-2;
  opacity: 0;
  overflow: hidden;
  padding-right: $spacing-1;
  position: absolute;
  transform: translateY(100%);
  transition: all 0.2s linear;
}

.savingIndicatorVisible {
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 1;
  transform: translateY(0);
}

.savingText {
  color: $vroom-link-teal;
  margin-left: $spacing-1;
}
