@import './colors';
@import './dimensions';
@import './spacing';
@import './zIndex';

.container {
  background-color: $white;
  position: relative;
  width: 100%;
  z-index: $footer-z-index;
}

.content {
  display: flex;
  flex-direction: column;
  height: $footer-height;
  justify-content: center;
  margin-left: $left-navigation-width;
  max-width: $max-width-content;
}

.links {
  text-align: center;

  a + a {
    margin-left: $spacing-2;
  }
}

.copyright {
  color: $text-gray;
  font-size: 11px;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: $text-gray;
    text-decoration: none;
  }

  a:hover {
    border-bottom: 1px solid $text-gray;
  }
}
