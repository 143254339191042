@import './colors';
@import './spacing';
@import './typography';

.container {
  margin-bottom: $spacing-4;
}

.title {
  color: $gray;
  font-size: 15px;
  font-weight: 500;
  line-height: 168%;
  margin: 0;
  text-transform: uppercase;
}

.subtitle {
  @extend %headline;
  color: $vroom-link-teal;
  padding-top: $spacing-2;

  p {
    margin: 0;
  }
}
