@import './colors';
@import './spacing';
@import './typography';

.container {
  padding: $spacing-4;
}

.blockList {
  border-bottom: 1px $border-gray solid;
  margin-bottom: $spacing-4;
}

.footer {
  p {
    margin: 0;
  }
}
