@import './colors';
@import './spacing';
@import './typography';

.container {
  display: table-caption;
  margin-top: $spacing-3;

  img {
    max-width: none;
  }
}

.title {
  @extend %smallHeadline;
  color: $white;
  padding: $spacing-1;
  text-align: center;
}

.caption {
  margin: 0;
  padding: 0 $spacing-3;
  text-align: center;
}

.contentGreen {
  .title {
    background-color: $secondary-green;
  }

  p {
    color: $secondary-green;
  }
}

.contentRed {
  .title {
    background-color: $secondary-red;
  }

  p {
    color: $secondary-red;
  }
}
