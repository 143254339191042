@import './typography';
@import './spacing';

.container {
  margin: 0 auto;
  max-width: 520px;
  padding-bottom: $spacing-4;
}

.containerDark {
  .contentContainer {
    background-color: $vroom-dark-teal;
    color: $white;
  }

  .link {
    color: $white;
  }

  .link::after {
    background-image: url('/icons/download-white.svg');
  }
}

.containerLight {
  .contentContainer {
    background-color: $secondary-light-blue;
    color: $text-brown;
  }

  .link {
    color: $text-brown;
  }

  .link::after {
    background-image: url('/icons/download-brown.svg');
  }
}

.title {
  @extend %secondaryHeadline;
}

.content {
  margin-bottom: $spacing-2;
  padding-top: $spacing-1;

  p {
    margin: 0;
  }

  p + p {
    margin-top: $spacing-2;
  }

  p + ul {
    margin-top: $spacing-1;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  ul {
    padding-inline-start: $spacing-2;
  }
}

.contentContainer {
  border-radius: 30px;
  padding: $spacing-4;
  position: relative;
  text-align: center;
}

.leftImage {
  left: -74px;
  position: absolute;
  top: 5px;
}

.rightImage {
  position: absolute;
  right: -74px;
  top: 5px;
}

.leftSparkles {
  left: -190px;
  position: absolute;
  top: 85px;
}

.rightSparkles {
  position: absolute;
  right: -200px;
  top: 90px;
}

.leftButterflies {
  left: -200px;
  position: absolute;
  top: 65px;
}

.rightButterflies {
  position: absolute;
  right: -200px;
  top: 80px;
}

.link {
  margin-top: $spacing-2;
}

.link::after {
  background-size: $spacing-2 $spacing-2;
  content: '';
  display: inline-flex;
  height: $spacing-2;
  margin-left: $spacing-1;
  vertical-align: text-top;
  width: $spacing-2;
}
