@import './colors';
@import './dimensions';
@import './spacing';

.container {
  padding: 0 $spacing-4 $spacing-4;
}

.containerSideBySide,
.containerFullWidthSideBySide {
  display: flex;

  .content + .content {
    margin-left: $spacing-5;
  }

  .content + .contentGrayBox {
    margin-left: $spacing-4;
  }

  .contentImage {
    align-items: center;
    display: flex;
  }

  .contentGrayBox {
    margin-top: 24px + $spacing-2;
  }

  .contentGrayBox div {
    margin-bottom: 0;
    max-width: $max-width-sm;
  }

  .contentEmbeddedContent {
    flex-basis: 50%;
  }
}

.containerSideBySide {
  .contentImage {
    max-width: $max-width-sm;
  }
}

.containerFullWidthSideBySide {
  padding-right: 0;
}

.containerTopSpacing,
.containerTopAndExtraBottomSpacing {
  padding-top: $spacing-4;
}

.containerHalfTopSpacing {
  padding-top: $spacing-3;
}

.containerExtraTopSpacing,
.containerExtraTopAndBottomSpacing {
  padding-top: $spacing-5;
}

.containerExtraBottomSpacing,
.containerExtraTopAndBottomSpacing,
.containerTopAndExtraBottomSpacing {
  padding-bottom: $spacing-5;
}

.containerHalfBottomSpacing {
  padding-bottom: $spacing-3;
}

.containerNoBottomSpacing {
  padding-bottom: 0;
}

.containerBeige {
  background-color: $background-beige;
}

.containerLavender {
  background-color: $background-lavender;
}

.containerLightBlue {
  background-color: $background-light-blue;
}

.contentReflection > div {
  padding-bottom: $spacing-3;
  padding-left: 0;
}

.contentGrayBox > div {
  padding: 0;
}
